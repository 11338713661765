import React, { PureComponent } from 'react';
import IconArrow from '../../components/Icons/IconArrow';
import SEO from '../../components/Common/SEO';
import { Form, Row, Col } from 'antd';
import Input from '../../components/Common/Input';
import { Link } from 'gatsby';
import constant from '../../utils/constant';
import Footer from '../../views/default-layout/footer/Footer';
import { InputType } from '../../utils/enums/InputType';
import { connect } from 'react-redux';
import { UserStatus } from '@utils/enums/UserStatus';
import { navigate } from 'gatsby';
import { userService } from '@services/userService';
import { LoginType } from '@utils/enums/LoginType';
import { message } from '@components/Message';
import countries from '@utils/countries.json';

const countryOptions = countries.data.map((i) => ({
   label: `${i.unicode_flag}  ${i.name}`,
   value: i.code,
}));

const formDefinitions = [
   {
      name: 'email',
      placeholder: 'Email *',
      inputTitle: (
         <>
            Email <span className="required">*</span>
         </>
      ),
      rules: [
         {
            required: true,
            message: 'Please input your Username.',
         },
         {
            type: 'email',
            message: 'Please use a valid email address.',
         },
      ],
   },
   {
      name: 'password',
      type: InputType.PASSWORD,
      placeholder: 'Password *',
      inputTitle: (
         <>
            Password <span className="required">*</span>
         </>
      ),
      rules: [
         {
            required: true,
            message: 'Please input your Password.',
         },
         {
            validator: async (_, value) => {
               const regex = /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/g;
               if (value && !regex.test(value)) {
                  return Promise.reject(
                     new Error(
                        'Password must have at least one letter and one number, contains at least 8 characters.'
                     )
                  );
               }
               return Promise.resolve();
            },
         },
      ],
   },
   {
      name: 'confirm',
      type: InputType.PASSWORD,
      placeholder: 'Confirm Password *',
      inputTitle: (
         <>
            Confirm Password <span className="required">*</span>
         </>
      ),
      dependencies: ['password'],
      rules: [
         {
            required: true,
            message: 'Please confirm your Password.',
         },
         ({ getFieldValue }) => ({
            validator(_, value) {
               if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
               }
               return Promise.reject(new Error('Please make sure your passwords match.'));
            },
         }),
      ],
   },
   {
      name: 'first_name',
      placeholder: 'First Name *',
      inputTitle: (
         <>
            First Name <span className="required">*</span>
         </>
      ),
      rules: [
         {
            required: true,
            message: 'Please input your First Name.',
         },
      ],
   },
   {
      name: 'last_name',
      placeholder: 'Last Name *',
      inputTitle: (
         <>
            Last Name <span className="required">*</span>
         </>
      ),
      rules: [
         {
            required: true,
            message: 'Please input your Last Name.',
         },
      ],
   },
   {
      name: 'country_code',
      type: InputType.SELECT,
      placeholder: 'Country *',
      inputTitle: (
         <>
            Country <span className="required">*</span>
         </>
      ),
      options: countryOptions,
      showSearch: true,
      allowClear: false,
      autoComplete: 'anyrandomstring',
      filterOption: (input, option) =>
         `${option.label}`.toLowerCase().indexOf(`${input}`.toLowerCase()) >= 0,
      rules: [
         {
            required: true,
            message: 'Please select your Country',
         },
      ],
   },
   {
      name: 'phone_number',
      placeholder: 'Phone Number',
      inputTitle: 'Phone Number',
   },
   {
      name: 'street_address',
      placeholder: 'Address',
      inputTitle: 'Address',
   },
   {
      name: 'city',
      placeholder: 'City',
      inputTitle: 'City',
   },
   {
      name: 'company',
      placeholder: 'Company',
      inputTitle: 'Company',
   },
   {
      name: 'postal_code',
      placeholder: 'Postal Code',
      inputTitle: 'Postal Code',
   },
];

class index extends PureComponent {
   state = {
      submitting: false,
      loading: true,
      requestSent: false,
      email: '',
      currentCountry: '',
   };
   _mounted = false;

   componentWillUnmount() {
      this._mounted = false;
   }

   componentDidMount() {
      this._mounted = true;
      this.checkUserLoggedIn();
   }

   componentDidUpdate(prevProps, prevState) {
      if (
         prevProps.loading !== this.props.loading ||
         prevProps.countryData !== this.props.countryData
      ) {
         this.checkUserLoggedIn();
      }
   }

   getCurrentCountry = async () => {
      this.setState({
         loading: false,
         currentCountry: this.props.countryData?.data?.country || '',
      });
   };

   checkUserLoggedIn = () => {
      const { loading, userProfile, countryData } = this.props;
      if (!loading && !countryData?.loading) {
         if (userProfile && userProfile.status === UserStatus.AVAILABLE) {
            if (userProfile.active) {
               navigate(constant.ROUTE_HOME);
               return;
            } else {
               navigate(constant.ROUTE_ACTIVATE);
               return;
            }
         }
         this.getCurrentCountry();
      }
   };

   onFinish = async (values) => {
      let body = { ...values, username: values.email };
      body.login_type = LoginType.CREDENTIALS;
      await this.setState({ submitting: true });
      const responseData = await userService.createUser(body);
      if (responseData.isSuccess) {
         const { active } = responseData.data;
         if (active) {
            message.success('Account registration successfully. Please login.');
            navigate(constant.ROUTE_LOGIN);
            return;
         } else {
            this.setState({
               requestSent: true,
               email: body.email || '',
            });
         }
      } else {
         message.error(responseData.message);
      }
      if (this._mounted) {
         this.setState({ submitting: false });
      }
   };

   requestActivation = async (email) => {
      await this.setState({ submitting: true });
      const response = await userService.requestActivation(email);
      if (response.isSuccess) {
         message.success('Resend email successfully.');
      } else {
         message.error(response.message);
      }
      if (this._mounted) {
         this.setState({ submitting: false });
      }
   };

   render() {
      const { loading, submitting, requestSent, email } = this.state;
      console.log(this.state.currentCountry);
      console.log(this.state.loading);
      return (
         <>
            <SEO title={'Sign Up'} />
            {!loading && (
               <>
                  <div className="login-register">
                     <div className="section-title">
                        <span className="section-title-name animate__animated animate__fadeIn">
                           SIGN UP
                           <span className="s-arrow animate__animated animate__rotateInDownLeft animate__slow">
                              <IconArrow />
                           </span>
                        </span>
                     </div>
                     <Row gutter={30}>
                        {!requestSent ? (
                           <Col lg={10} md={12} sm={24} xs={24}>
                              <>
                                 <Form
                                    initialValues={{
                                       country_code: this.state.currentCountry,
                                    }}
                                    className="submit-form"
                                    validateMessages={this.validateMessages}
                                    onFinish={this.onFinish}
                                 >
                                    {formDefinitions.map((item) => {
                                       return (
                                          <Form.Item
                                             key={item.name}
                                             name={item.name}
                                             dependencies={item.dependencies}
                                             rules={item.rules || []}
                                          >
                                             <Input
                                                {...item}
                                                type={item.type}
                                                placeholder={item.placeholder}
                                             />
                                          </Form.Item>
                                       );
                                    })}
                                    <Form.Item>
                                       <button
                                          className="common-button"
                                          type="primary"
                                          disabled={submitting}
                                       >
                                          {submitting ? 'Signing Up' : 'Sign Up'}
                                       </button>
                                    </Form.Item>
                                 </Form>
                                 <Link
                                    to={constant.ROUTE_LOGIN}
                                    className="link-register"
                                 >
                                    Already have an Account? Sign In
                                 </Link>
                              </>
                           </Col>
                        ) : (
                           <Col lg={12} md={12} sm={24} xs={24}>
                              <div className="request-sent">
                                 <p>
                                    We've sent an email to:{' '}
                                    <span className="email">{email}</span>. Please check
                                    the email to activate your account.
                                 </p>
                                 <div>You didn't receive any email?</div>
                                 <button
                                    className="common-button__outline"
                                    disabled={submitting}
                                    onClick={() => this.requestActivation(email)}
                                 >
                                    {submitting ? 'Re-sending' : 'Resend email'}
                                 </button>
                              </div>
                           </Col>
                        )}
                     </Row>
                  </div>
                  <Footer />
               </>
            )}
         </>
      );
   }
}

const mappingState = (state, ownProps) => {
   return {
      loading: state.profile.loading,
      userProfile: state.profile.data,
      countryData: state.common.country,
   };
};

export default connect(mappingState)(index);
